import Section from 'src/components/sections/Section'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Lazy } from 'swiper'
import { Link } from 'gatsby'
import './sectionBrand.scss'

// eslint-disable-next-line
const SectionBrand = (dataCMS: any, slidesPerView: number) => {
  const dataBrand = dataCMS.data.sections[0].data.items

  return (
    <div className="section-info-brand mb-12 flex items-center justify-between layout__content">
      <div className="info-brand">
        <div className="flex items-center">
          {dataBrand.sectionA.brandLogo !== '' && (
            <img
              className="image-brand"
              alt={`${dataBrand.sectionA.brandSports} - ${dataBrand.sectionA.brandName}`}
              title={`${dataBrand.sectionA.brandSports} - ${dataBrand.sectionA.brandName}`}
              src={dataBrand.sectionA.brandLogo}
            />
          )}

          <div className="data-brand flex flex-col">
            {dataBrand.sectionA.brandName !== '' && (
              <h1 className="brand-name mt-0 mb-0.5">
                {dataBrand.sectionA.brandName}
              </h1>
            )}
            {dataBrand.sectionA.brandSports !== '' && (
              <h2 className="brand-type">{dataBrand.sectionA.brandSports}</h2>
            )}
          </div>
        </div>
      </div>

      <p className="title-categories md:hidden">Navegue por categorias</p>
      <Section className="slick-slide-section pictos-brands layout__content layout__section">
        <ul>
          <Swiper
            className="slick-slide-swiper"
            slidesPerView={3}
            navigation={{
              prevEl: '.arrow-brands-prev',
              nextEl: '.arrow-brands-next',
            }}
            breakpoints={{
              1244: {
                slidesPerView: 3,
                spaceBetween: 14,
              },
              1190: {
                slidesPerView: 3,
                spaceBetween: 14,
              },
              1100: {
                slidesPerView: 4,
                spaceBetween: 14,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 14,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 14,
              },
              420: {
                slidesPerView: 4,
                spaceBetween: 14,
              },
              375: {
                slidesPerView: 3,
                spaceBetween: 4,
              },
              320: {
                slidesPerView: 3,
                spaceBetween: 4,
              },
            }}
            lazy={{
              loadPrevNext: true,
              loadPrevNextAmount: slidesPerView,
            }}
            modules={[Navigation, Lazy]}
          >
            <div className="arrow-brands-prev !flex bg-lighestGray" />
            <div className="arrow-brands-next !flex bg-lighestGray" />
            {dataBrand.sectionC.categoriesBrand.map(
              // eslint-disable-next-line
              (item: any, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <li className="slick-slide-item">
                      <Link
                        to={item.categoryURL}
                        className="slick-slide-item"
                        rel="nofollow"
                      >
                        <div className="content-image">
                          <img
                            alt={item.categoryName}
                            title={item.categoryName}
                            src={item.categoryPicto}
                          />
                        </div>

                        <p className="text-neutral10">{item.categoryName}</p>
                      </Link>
                    </li>
                  </SwiperSlide>
                )
              }
            )}
          </Swiper>
        </ul>
      </Section>
    </div>
  )
}

export default SectionBrand
