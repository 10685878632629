import { useMobile } from 'src/hooks/useMobile'

import './mainBanner.scss'

// eslint-disable-next-line
const MainBannerBrands = (dataBanner: any) => {
  const { screenWidth } = useMobile()

  return (
    <div className="main-banner-brand">
      {screenWidth >= 1024 ? (
        <div className="main-banner-brand__desktop">
          {dataBanner.data.brandSEOTitle !== '' && (
            <img
              alt={`${dataBanner.data.brandName} ${dataBanner.data.brandSports}`}
              title={dataBanner.data.brandSEOTitle}
              src={dataBanner.data.brandImageDesktop}
            />
          )}
        </div>
      ) : (
        <div className="main-banner-brand__mobile">
          {dataBanner.data.brandImageMobile !== '' && (
            <img
              alt={`${dataBanner.data.brandName} ${dataBanner.data.brandSports}`}
              src={dataBanner.data.brandImageMobile}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MainBannerBrands
